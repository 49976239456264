import { Component } from '@angular/core';
import firebase from 'firebase';
import 'firebase/auth';
import { firebaseConfig } from './firebaseConfig';
import { HttpClient } from '@angular/common/http';
import { apiLink } from 'src/environments/environment.prod';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Charger', url: '/folder/charger', imgPath: 'assets/images/charging-station.png' },
    { title: 'Connector', url: '/folder/connector', imgPath: 'assets/images/ev-connector-icon.png' },
    { title: 'Charging Card', url: '/folder/chargeCard', imgPath: 'assets/images/charge-cards.png' },
    { title: 'Transaction', url: '/folder/transaction', icon: 'reader' },
    { title: 'Charging Cost', url: '/folder/chargingCost', imgPath: 'assets/images/money-fill.png' },
    { title: 'Configuration', url: '/folder/configuration', icon: 'settings' },
    { title: 'Permission', url: '/folder/permission', icon: 'document-lock' },
    { title: 'Admin', url: '/folder/admin', icon: 'person-circle' },
    { title: 'Chart', url: '/folder/chart', icon: 'stats-chart' },
    // { title: 'Test', url: '/folder/test', icon: 'ticket' },
  ];

  permission

  constructor(private http: HttpClient) {
    firebase.initializeApp(firebaseConfig);
  }

  ngOnInit() {

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        console.log(user.uid);

        this.http.post(apiLink + 'checkUserPermission', { firebaseId: user.uid}).subscribe((s) => {
          // console.log(s['permission']);

          this.permission = s['permission'].filter(a => a.permission_type == 'read').map(a => a.resource)

          // console.log(this.permission);
        })
      }

    });


  }

}
