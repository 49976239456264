import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'folder/charger',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'profile-edit',
    loadChildren: () => import('./profile-edit/profile-edit.module').then( m => m.ProfileEditPageModule)
  },
  {
    path: 'charger-add',
    loadChildren: () => import('./charger-add/charger-add.module').then( m => m.ChargerAddPageModule)
  },
  {
    path: 'charger-edit',
    loadChildren: () => import('./charger-edit/charger-edit.module').then( m => m.ChargerEditPageModule)
  },
  {
    path: 'connector-edit',
    loadChildren: () => import('./connector-edit/connector-edit.module').then( m => m.ConnectorEditPageModule)
  },
  {
    path: 'transaction-details',
    loadChildren: () => import('./transaction-details/transaction-details.module').then( m => m.TransactionDetailsPageModule)
  },
  {
    path: 'cost-profile-edit',
    loadChildren: () => import('./cost-profile-edit/cost-profile-edit.module').then( m => m.CostProfileEditPageModule)
  },
  {
    path: 'cost-profile-add',
    loadChildren: () => import('./cost-profile-add/cost-profile-add.module').then( m => m.CostProfileAddPageModule)
  },
  {
    path: 'charge-card-add',
    loadChildren: () => import('./charge-card-add/charge-card-add.module').then( m => m.ChargeCardAddPageModule)
  },
  {
    path: 'charge-card-edit',
    loadChildren: () => import('./charge-card-edit/charge-card-edit.module').then( m => m.ChargeCardEditPageModule)
  },
  {
    path: 'config-edit',
    loadChildren: () => import('./config-edit/config-edit.module').then( m => m.ConfigEditPageModule)
  },
  {
    path: 'permission-add',
    loadChildren: () => import('./permission-add/permission-add.module').then( m => m.PermissionAddPageModule)
  },
  {
    path: 'permission-edit',
    loadChildren: () => import('./permission-edit/permission-edit.module').then( m => m.PermissionEditPageModule)
  },  {
    path: 'admin-add',
    loadChildren: () => import('./admin-add/admin-add.module').then( m => m.AdminAddPageModule)
  },
  {
    path: 'admin-edit',
    loadChildren: () => import('./admin-edit/admin-edit.module').then( m => m.AdminEditPageModule)
  },
  {
    path: 'admin-log',
    loadChildren: () => import('./admin-log/admin-log.module').then( m => m.AdminLogPageModule)
  },
  {
    path: 'camera',
    loadChildren: () => import('./camera/camera.module').then( m => m.CameraPageModule)
  },
  {
    path: 'transaction-report',
    loadChildren: () => import('./transaction-report/transaction-report.module').then( m => m.TransactionReportPageModule)
  },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
